<template>
    <div class="addNotePage">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="$router.go(-1)">
            <template #left class="left">
                <van-icon name="arrow-left" size="22" color="#141414" />
            </template>
            <template #title>
                <span style="font-size:17px;font-weight:500;">新增笔记/编辑笔记</span>
            </template>
            <!-- <template #right>
                <span>全部缓存</span>
            </template> -->
        </van-nav-bar>

        <div class="textArea">
            <van-field v-model="content" rows="12" autosize type="textarea" maxlength="1000" show-word-limit />
        </div>

        <div class="btn">
            <van-button class="btn_btn" @click="noteSubmit" type="primary" color="#5F7DFF" block>保存</van-button>
        </div>
    </div>
</template>

<script>
import { noteAdd, noteList, noteEdit } from '@/api/selectClass.js'

export default {
    data() {
        return {
            // 套餐id:coursePackageId > 课程id:courseId > 章节id:chapterId > 课时id:classHourId
            content: '',
            coursePackageId: '',
            courseId: '',
            chapterId: '', //暂时没用到章节id
            classHourId: '',
            
            id:'', //要编辑的笔记id
            from: '', //来源：判断新加笔记还是编辑笔记
        }
    },
    created() {
        if (this.$route.query.coursePackageId) this.coursePackageId = this.$route.query.coursePackageId
        if (this.$route.query.courseId) this.courseId = this.$route.query.courseId
        if (this.$route.query.chapterId) this.chapterId = this.$route.query.chapterId //暂时没用到章节id
        if (this.$route.query.classHourId) this.classHourId = this.$route.query.classHourId
        if (this.$route.query.from) this.from = this.$route.query.from

        if(this.$route.query.id) this.id = this.$route.query.id
        if(this.$route.query.from == 'edit') this.getNoteList()

    },
    methods: {
        // 获取笔记
        async getNoteList(){
            let data = new FormData()
            data.append('coursePackageId',this.coursePackageId)
            data.append('courseId',this.courseId)
            data.append('classHourId',this.classHourId)
            data.append('content',this.content)
            
            let res = await noteList(data)
            if(res.data.code != 0) return this.$toast('未获取到笔记')

            res.data.data.forEach((item,i) => {
                if(item.id == this.id){
                    this.content = item.content
                }
            });
            console.log('获取笔记222------',res)
        },
        // 保存
        async noteSubmit() {
            if(this.from == 'add') this.addNoteFun() //新增
            if(this.from == 'edit') this.noteEditFun() //修改
            this.$router.go(-1)
        },
        // 新增笔记
        async addNoteFun(){
            let data = new FormData()
            data.append('coursePackageId', this.coursePackageId)
            data.append('courseId', this.courseId)
            data.append('classHourId', this.classHourId)
            data.append('content', this.content)

            let res = await noteAdd(data)
            if (res.data.code == 0) this.$toast('添加成功')
            if (res.data.code != 0) this.$toast(res.data.msg)
        },
        // 修改笔记
        async noteEditFun(){
            let data = new FormData()
            data.append('coursePackageId', this.coursePackageId)
            data.append('courseId', this.courseId)
            data.append('classHourId', this.classHourId)
            data.append('content', this.content)
            data.append('id', this.id)

            let res = await noteEdit(data)
            if (res.data.code == 0) this.$toast('修改成功')
            if (res.data.code != 0) this.$toast(res.data.msg)
        }

    },
}
</script>

<style lang="less" scoped>
.addNotePage {
    height: 100vh;
    background-color: #f5f5f5;

    .textArea {
        padding: 10px;
        margin-bottom: 70px;
    }

    .btn {
        display: flex;
        justify-content: center;
        .btn_btn {
            width: 92%;
            border-radius: 5px;
        }
    }
}
</style>

