import { render, staticRenderFns } from "./addNotePage.vue?vue&type=template&id=2c092029&scoped=true&"
import script from "./addNotePage.vue?vue&type=script&lang=js&"
export * from "./addNotePage.vue?vue&type=script&lang=js&"
import style0 from "./addNotePage.vue?vue&type=style&index=0&id=2c092029&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c092029",
  null
  
)

export default component.exports